import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HexagonLoader from './assets/loader.gif';
// import packageJson from "../package.json";
// import { getBuildDate } from "./utils/utils";
import withClearCache from './ClearCache';
const ClearCacheComponent = withClearCache(MainApp);

const Staking = lazy(() => retry(() => import('./pages/landingPage')));
const HomePage =lazy(()=> retry(()=>import('./pages/HomePage')));
const Legal = lazy(()=> retry(()=>import('./pages/legal')))

// lazy load check
function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img className='loader-img' src={HexagonLoader} alt='' />
          </div>
        }
      >
        <Router>
          <Switch>
            {/* <Route exact path='/' component={()=><HomePage />} /> */}
            <Route exact path='/' component={() => <Staking />} />
            <Route exact path='/legal' component={() => <Legal/>} />
          </Switch>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
